import * as React from 'react'
import { randomLongNumber } from '../api/helpers'
import { baseURL } from '../Utils/const'

export const Videocomponent = ({ source }) => {
    const videoRef = React.useRef(null);
    const [vi, setvi] = React.useState(false)

    const onEvenetVideo = (e) => {
        e.preventDefault()
        if (vi) videoRef.current.pause();
        else videoRef.current.play();
        setvi(!vi)
    }

    return (
        <div
            className={`${vi ? "video-btn-p" : "video-btn"} bg-blue-gradiant- pointer px-0`}
            onClick={(e) => onEvenetVideo(e)}
        >
            <video
                ref={videoRef}
                autoPlay
                muted
                loop
                className="float-right w-100"
                style={{ borderRadius: 10 }}
                controls
                preload="none"
                onPlay={() => {
                    setvi(true)
                }}
                onPause={() => {
                    setvi(false)
                }}
                onClick={(e) => onEvenetVideo(e)}
            >
                <source src={`${baseURL}/${source}`} type="video/mp4" />
            </video>
        </div>
    )
}