import React, { Fragment, useRef, useState } from 'react';
import Webcam from 'react-webcam';

export const CameraLive = ({ cb }) => {
    const webcamRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);

    const capture = () => {
        const image = webcamRef.current.getScreenshot();
        setImageSrc(image);
        return cb(image)
    };

    return (
            <Fragment>
                <div className="overlay">
                    <div className="modal-content">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-0 mb-0 rounded-3 overflow-hidden chat-wrapper bg-image-center bg-image-cover"
                                    style={{ backgroundImage: `url("https://via.placeholder.com/975x700.png")`, position: 'relative' }}>
                                    <div className="card-body position-absolute mt-0 ms-0 left-0 d-flex justify-content-center" style={{ width: '100%', height: '100%' }}>
                                        <Webcam
                                            audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            width={320}
                                            height={240}
                                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="card-body text-center p-2 position-absolute w-100 bottom-0 bg-gradiant-bottom">
                                        <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); capture() }} className="btn-round-xxl bg-danger z-index-1"><i className="feather-phone-off text-white font-md"></i></a>
                                        <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); return cb(null) }} className="btn-round-xxl bg-grey- z-index-1"><i className="ti-close text-dark- ml-4 font-md d-none"></i></a>
                                        <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); return cb(null) }} className="btn-round-xxl bg-grey z-index-1"><i className="ti-close text-dark ml-4 font-md"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    );
};

export default CameraLive;
