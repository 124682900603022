import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { appName } from '../Utils/const';
import { routes } from '../api/routes';

class Appfooter extends Component {
    render() {
        return (
            <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
                <Link to={routes['home']} className="nav-content-bttn nav-center"><i className="feather-home"></i></Link>
                {/* <Link to="#" className="nav-content-bttn"><i className="feather-package"></i></Link> */}
                <Link to={routes['notifications']} className="nav-content-bttn" data-tab="chats"><i className="feather-bell"></i></Link>
                {/* <Link to="/shop2" className="nav-content-bttn"><i className="feather-layers"></i></Link> */}
                <Link to={routes['profile']} className="nav-content-bttn"><i className='feather-user'></i></Link>
            </div>
        );
    }
}

export default Appfooter;