import React, { Component } from 'react';
import { useUser } from './Userchecker';
import { call } from '../api/apicall';
import toast from 'react-hot-toast';

export const Profiledetail = () => {

    const [infos, setinfos] = React.useState({})
    const [isloading, setisloading] = React.useState(false);

    const user = useUser();
    const { getAdresse, getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getBio } = user

    const onLoad = async () => {
        setisloading(true)
        call.onRunExternalRQST({
            method: "GET",
            url: `/users/user/${getID()}`
        })
            .then(res => {
                setisloading(false)
                const { status, data: asdata } = res
                if (status === 200) {
                    const { data } = asdata
                    setinfos(data)
                }
            })
            .catch(err => {
                setisloading(false)
                toast.error("Erreur de chargement des informations !")
            })
    }

    React.useEffect(() => {
        onLoad()
    }, []);

    return (
        <>
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">{getCredits()}</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Mes crédits</p>
                </div>
            </div>
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">{infos.hasOwnProperty("followers") ? infos['followers'] : "---"}</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Mes fans</p>
                </div>
            </div>
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">{getCredits()}</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">Mes posts</p>
                </div>
            </div>
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-block p-4">
                    <h4 className="fw-700 mb-3 font-xsss text-grey-900">Bio</h4>
                    <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{getBio()}</p>
                </div>
                <div className="card-body border-top-xs d-flex">
                    <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Privé <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Des postes masqués</span></h4>
                </div>

                <div className="card-body d-flex pt-0">
                    <i className="feather-eye text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-0">Visibilité <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visible por tout le monde</span></h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Adresse <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{getAdresse()}</span> </h4>
                </div>
                <div className="card-body d-flex pt-0">
                    <i className="feather-users text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Genarel Group</h4>
                </div>
            </div>
        </>

    );
}

export default Profiledetail;