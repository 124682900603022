export const routes = {
    "signin": "/app/auth/signin",
    "signup": "/app/auth/signup",
    "sessionreset": "/app/auth/resetsession",
    "forgot": "/app/auth/forgot",
    "verify": "/app/auth/verify",

    "home": "/app/home",
    "notifications": "/app/notifications",
    "profile": "/app/profile",
    "fallback": "/",
    "notfound": "/app/notfound",
    "member": "/app/member"
}