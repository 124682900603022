import React, { Component, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardThree from '../components/ProfilecardThree';
import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import { useUser } from "../components/Userchecker";
import { Feeds } from "../components/Feed";

export const Userpage = () => {
    const [l, setl] = React.useState(false)
    const user = useUser()
    const { getID } = user

    React.useEffect(() => {

    }, []);

    return (
        <Fragment>
            <Header />
            {/* <Leftnav /> */}
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12 mb-3">
                                <ProfilecardThree />
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                <Profiledetail />
                                {/* <Profilephoto /> */}
                                {/* <Events /> */}
                            </div>
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <Createpost />
                                <Feeds
                                    cb={l}
                                    id_member={getID()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

        </Fragment>
    );
}

export default Userpage;