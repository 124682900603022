import * as React from 'react';
import { checkLifeSession, retrieveSession } from '../api/session';
import { LoaderScreen } from '../pages/Loader';

const UserContext = React.createContext(null);

export const UserProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    const { getToken } = _user;
                    const bearer = getToken();
                    checkLifeSession({
                        token: bearer,
                        callBack: (err, done) => {
                            if (done) {
                                const { code } = done;
                                if (code === 200) {
                                    setUser(_user);
                                } else {
                                    setUser(null);
                                }
                            } else {
                                setUser(null);
                            }
                            setLoading(false);
                        }
                    });
                } else {
                    setUser(null);
                    setLoading(false);
                }
            }
        });
    };

    React.useEffect(() => {
        loadCurrentUser();
    }, []);

    if (loading) {
        return <LoaderScreen />; // Affichage du loader pendant le chargement
    }

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => React.useContext(UserContext);
