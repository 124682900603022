import React, { createContext, Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../Utils/styles';
import { routes } from '../api/routes';
import { TbNetworkOff } from 'react-icons/tb';

// Création d'un contexte pour la connexion
const ConnectionContext = createContext();

export const useConnection = () => {
    return useContext(ConnectionContext);
};

export const ConnectionProvider = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const updateOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    return (
        <ConnectionContext.Provider value={isOnline}>
            {children}
            {/* {isOnline ? children : <OfflinePage />} */}
        </ConnectionContext.Provider>
    );
};

const OfflinePage = () => {
    return (
        <Fragment>
            <div className="main-content pt-0 bg-white ps-0 pe-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                            <div className="card border-0 text-center d-block p-0">
                                <TbNetworkOff size={150} color={Colors.primaryColor} />
                                <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Oops! On dirait que vous êtes deconnecté.</h1>
                                <p className="text-grey-500 font-xsss">Il semble que vous êtes hors connexion, vous devez vous connecter sur internet avant de continuer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
