import randomstring from "randomstring";
import dotenv from 'dotenv';
import path from 'path'

dotenv.config();

const { APPPORT, APPCOOKIESNAME, APPRATELIMITMAXREQS, APPRATELIMITTIMING, APPAPIKEY, APPNAME } = process.env;
export const maxShowLengthStringPost = 350;

export const generateIdentifier = ({ prefix }) => {
    const pfx = Math.floor(Math.random() * 1000);
    const sfx = Math.floor(Math.random() * 100);

    return `${prefix ? String(prefix).toUpperCase().concat("-") : ""}${randomLongNumber({ length: 10 })}`;
};

export const getFileExtesnsion = ({ fileName }) => {
    return path.extname(fileName).toLowerCase()
};

export const truncateString = ({ text }) => {
    return String(text).substring(0, maxShowLengthStringPost).concat(" ...")
}

export const getFileType = ({ fileName }) => {
    const extension = path.extname(fileName).toLowerCase();

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
    const videoExtensions = ['.mp4', '.avi', '.mov', '.wmv', '.flv', '.mkv', '.webm'];
    const audioExtensions = ['.mp3', '.wav', '.aac', '.flac', '.ogg', '.wma', '.m4a'];
    const documentExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt'];

    if (imageExtensions.includes(extension)) {
        return 1 //'image';
    } else if (videoExtensions.includes(extension)) {
        return 2 //'video';
    } else if (audioExtensions.includes(extension)) {
        return 3 //'audio';
    } else if (documentExtensions.includes(extension)) {
        return 4 // 'document';
    } else {
        return 5 // 'other';
    }
}

export const generateFilename = ({ prefix, tempname }) => {
    const extension = tempname.substring(tempname.lastIndexOf("."));
    return `${prefix ? prefix + "-" : ""}${randomstring.generate()}${extension}`;
};

export const randomLongNumber = ({ length }) => {
    const len = length && !isNaN(parseInt(length)) ? length : 6;
    const ret = [];

    for (let k = 0; k < len; k++) ret.push(
        Math.floor(Math.random() * 10)
    );

    let m = ret.join().toString();
    m = m.replace(/,/g, "");
    return m.trim();
};

export const randomLongNumberWithPrefix = ({ length }) => {
    const prefix = APPNAME;
    const len = length && !isNaN(parseInt(length)) ? length : 6;
    const ret = [];

    for (let k = 0; k < len; k++) ret.push(
        Math.floor(Math.random() * 10)
    );

    let m = ret.join().toString();
    m = m.replace(/,/g, "");
    return `${prefix}${m.trim()}`;
};

export const randomString = () => randomstring.generate({ length: 32 })
