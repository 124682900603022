import React, { Component } from 'react';
import { baseURL } from '../Utils/const';
import { FriendComponent } from './Friend';
import { call } from '../api/apicall';

export const Friends = () => {

    const [friendList, setfriendList] = React.useState([])
    const [isloading, setisloading] = React.useState(false);
    const limit = 10 / 2

    const onLoad = async () => {
        setisloading(true)
        call.onRunExternalRQST({
            url: "/users/listall/requestfriendship",
            method: "GET"
        })
            .then(done => {
                setisloading(false)
                const { data: asdata, status } = done
                if (status === 200) {
                    const { data } = asdata
                    const { list } = data
                    const _ = Array.from(list).slice(0, limit).map(r => {
                        const { __tbl_user, id_member, createdon, status, id } = r
                        const { nom, postnom, prenom, email, avatar } = __tbl_user
                        return {
                            nom,
                            id,
                            avatar,
                            postnom,
                            prenom,
                            email,
                            friends: 0
                        }
                    })
                    setfriendList(_)
                } else {
                    setfriendList([])
                }
            })
            .catch(err => {
                setfriendList([])
            })
    }

    React.useEffect(() => {
        onLoad()
    }, [])

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Démande d'amis</h4>
                <a href="#" onClick={(e) => { onLoad(); e.preventDefault() }} className="fw-600 ms-auto font-xssss text-primary">Voir tout</a>
            </div>
            {friendList.map((value, index) => {
                return <FriendComponent
                    data={value}
                    key={index * Math.round() * Math.random()}
                    cb={_ => {
                        if (_ === true) {
                            onLoad()
                        }
                    }}
                />
            })}
        </div>
    );
}

export default Friends;