import * as React from 'react'
import { baseURL } from '../Utils/const'

export const ImageComponent = ({ source }) => {

    return (
        <div className="card-body d-block p-0 mb-3">
            <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1">
                    <img src={`${baseURL}/${source}`} className="rounded-3 w-100" alt="post" />
                </div>
            </div>
        </div>
    )
}