import React, { Fragment } from "react";
import { routes } from "../api/routes";
import { appName } from "../Utils/const";
import bg from "../assets/Images/auth.png";
import { Headerauth } from "../components/Authheader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { onRunExternalRQST } from "../api/apicall";
import toast from "react-hot-toast";
import { retrieveSession, saveSession } from "../api/session";
import { useHistory } from "react-router-dom";
import Load from "../components/Load";
import { UserContext } from "../components/Userchecker";
import { Link } from "react-router-dom";
import { Authwithgoogle } from "../components/Authwithgoogle";

export const Login = () => {

    const [isloading, setisloading] = React.useState(false);
    const history = useHistory();
    const [user, setUser] = React.useState({});
    const [showPassword, setShowPassword] = React.useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            rememberMe: false,
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Adresse mail ou nom d\'utilisateur est requis'),
            password: Yup.string()
                .required('Mot de passe est requis'),
        }),
        onSubmit: (values) => {
            setisloading(true)
            const { username, rememberMe, password } = values
            onRunExternalRQST({
                data: { phone: username, password, rememberMe },
                method: "POST",
                url: "/users/user/signin"
            })
                .then(res => {
                    setisloading(false)
                    const { message, data, status } = res
                    switch (status) {
                        case 200:
                            toast.success("Authentification réussie !")
                            const { data: as_data } = data;
                            const { user, token, reftoken } = as_data
                            saveSession({
                                currentUser: { ...user, token, reftoken },
                                rememberMe: true,
                                callBack: (e, d) => {
                                    if (d) {
                                        retrieveSession({
                                            callBack: (eR, dR) => {
                                                if (dR) {
                                                    window.location.replace(`#${routes['home']}?_redirect=1`)
                                                    // history.replace(routes['home']);
                                                } else {
                                                    toast.error("Une erreur de connexion vient de se produire, réessayer un peu plus tard !", {
                                                        position: "bottom-center",
                                                        duration: 10
                                                    });
                                                }
                                            }
                                        })

                                    } else {
                                        toast.error("Une erreur de connexion vient de se produire ", {
                                            position: "bottom-center",
                                            duration: 10
                                        });
                                    }
                                }
                            })
                            break;
                        case 203:
                            toast.error("Mot de passe ou nom d'utilisateur est incorrect !")
                            break;
                        case 405:
                            toast.error(`Il semble que cette adresse n'est pas encore validée, nous venons de vous envoyer un code vériication `)
                            history.push({
                                pathname: routes['verify'],
                                state: { ...data.data }
                            })
                            break;
                        default:
                            toast.error("Une erreur vient de se produire lors du traitement !")
                            break;
                    }
                })
                .catch(err => {
                    setisloading(false)
                    toast.error("Une erreur vient de se produire lors du traitement !")
                })
        },
    });

    return (
        <Fragment>
            <div className="main-wrap">
                <Headerauth />
                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-3">Connectez-vous à <br />votre compte</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                        <input
                                            type="text"
                                            className={`style2-input ps-5 form-control text-grey-900 font-xsss fw-600 ${formik.errors.username && formik.touched.username ? 'is-invalid' : ''}`}
                                            placeholder="Adresse mail ou nom d'utilisateur"
                                            id="username"
                                            name="username"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.username}
                                        />
                                        {formik.errors.username && formik.touched.username ? (
                                            <div className="invalid-feedback">{formik.errors.username}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group icon-input mb-1">
                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className={`style2-input ps-5 form-control text-grey-900 font-xss ls-3 ${formik.errors.password && formik.touched.password ? 'is-invalid' : ''}`}
                                            placeholder="Mot de passe"
                                            id="password"
                                            name="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        <span
                                            className={`font-sm ${!showPassword ? "ti-eye" : "feather-eye-off"} text-grey-500 pe-0 pointer`}
                                            style={{ position: "absolute", right: "15px", top: "19px" }}
                                            onClick={e => {
                                                e.preventDefault()
                                                togglePasswordVisibility()
                                            }}
                                        />
                                        {formik.errors.password && formik.touched.password ? (
                                            <div className="invalid-feedback">{formik.errors.password}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-check text-left mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mt-2"
                                            id="rememberMe"
                                            name="rememberMe"
                                            onChange={formik.handleChange}
                                            checked={formik.values.rememberMe}
                                        />
                                        <label className="form-check-label font-xsss text-grey-500" htmlFor="rememberMe">Se souvenir de moi</label>
                                        <Link to={`#${routes['forgot']}`} className="fw-600 font-xsss text-grey-700 mt-1 float-right" onClick={(e) => { e.preventDefault() }} >Mot de passe oublié ?</Link>
                                    </div>

                                    <div className="col-sm-12 p-0 text-left">
                                        <div className="form-group mb-1">
                                            {isloading ?
                                                (
                                                    <Load />
                                                )
                                                :
                                                (
                                                    <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                                        Connexion
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <div className="form-group mb-1" hidden={isloading}>
                                            <Authwithgoogle
                                                text={"signin_with"}
                                                context={1}
                                                cb={_ => {

                                                }}
                                            />
                                        </div>
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            Vous n'avez pas de compte ?
                                            <a href={`#${routes['signup']}`} className="fw-700 ms-1">Créer un compte</a>
                                        </h6>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
