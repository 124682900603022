import React, { Component } from 'react';
import Live from '../pages/Live';
import { CameraLive } from './Livephoto';
import toast from 'react-hot-toast';
import Postmanager from './Beforepost';
import { useHistory } from 'react-router-dom';
import { useUser } from './Userchecker';
import { baseURL } from '../Utils/const';
import { call } from '../api/apicall';
import Load from './Load';

export const Createpost = ({ cb }) => {
    const [isOpen, setisOpen] = React.useState(false);
    const [isCameraOpen, setIsCameraOpen] = React.useState(false);
    const [isCameraLiveOpen, setisCameraLiveOpen] = React.useState(false)
    const toggleOpen = () => setisOpen(!isOpen);
    const menuClass = `${isOpen ? " show" : ""}`;
    const fileInputRef = React.useRef(null);
    const [files, setFiles] = React.useState([])
    const [show, setshow] = React.useState(false)
    const messageRef = React.useRef(null);
    const [message, setmessage] = React.useState("")
    const [isloading, setisloading] = React.useState(false);

    const user = useUser();
    const history = useHistory();
    const { getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getCoverImage } = user

    const openDeviceImages = () => {
        toggleOpen()
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            toast.error("Erreur de référencement dans le formulaire !")
            console.error("File input reference is not available.");
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files;
        if (selectedFile) {
            const __ = []
            Object.keys(selectedFile).map(_ => {
                const file = selectedFile[_]
                __.push(file);
            })
            setFiles([...files, ...__])
            setshow(true)
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const mess = messageRef.current.value.trim();
        if (!mess) return messageRef.current.style.border = '2px solid red';

        messageRef.current.style.border = '1px solid #ced4da'; // Reset to default border color

        setisloading(true)
        call.onRunExternalRQST({
            data: {
                content: mess,
                typepost: 1,
                from_id: 0,
                from_id_post: 0
            },
            method: "POST",
            url: "/posts/post/add"
        })
            .then(res => {
                setisloading(false);
                const { status, message, data } = res
                if (status === 200) {
                    e.target.reset()
                    setmessage("")
                    toast.success("Post envoyé avec succès")
                    cb(true)
                } else {
                    toast.error("Une erreur vient de se produire, veullez réessayer un peu plus tard !")
                    cb(false)
                }
            })
            .catch(err => {
                setisloading(false)
                toast.error("Une erreur vient de se produire, veullez réessayer un peu plus tard !")
                cb(false)
            })
    }

    return (
        <>
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <div className="card-body p-0">
                    <a href="#" onClick={(e) => e.preventDefault()} className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Créer un post</a>
                </div>
                <form onSubmit={(e) => { handleSubmit(e) }}>
                    <div className="card-body p-0 mt-3 position-relative">
                        <figure className="avatar position-absolute ms-2 mt-1 top-5">
                            <img src={`${baseURL}/${getAvatar()}`} alt="icon" className="shadow-sm rounded-circle w30" />
                        </figure>
                        <textarea
                            name="message"
                            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500- fw-500 border-light-md theme-dark-bg"
                            cols="30"
                            rows="10"
                            placeholder={`${getNom()} ${getPostnom()}, c'est quoi la motivation aujourd'hui ?`}
                            ref={messageRef}
                            onChange={e => setmessage(e.target.value)}
                            onClick={(e) => {
                                e.preventDefault()
                                // messageRef.current.style.background = Colors.secondaryColor
                                messageRef.current.style.fontSize = 23
                                messageRef.current.style.color = "black"
                            }}
                        />
                    </div>
                    {String(message).trim().length > 0 && (
                        <div className="form-group mb-3">
                            {isloading ? (
                                <Load />
                            ) : (
                                <button
                                    type="submit"
                                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                >
                                    Publier
                                </button>
                            )}
                        </div>
                    )}
                </form>
                <div className="card-body d-flex p-0 mt-0">
                    <a
                        href="#video"
                        onClick={e => {
                            e.preventDefault();
                            setIsCameraOpen(!isCameraOpen)
                        }}
                        className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                        <i className="font-md text-primary feather-video me-2"></i>
                        <span className="d-none-xs">Une vidéo en direct</span>
                    </a>
                    {/* =================== Photos ===================== */}
                    <div className={`ms-auto pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}>
                        <a href="#photo" onClick={(e) => e.preventDefault()} className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-primary feather-image me-2"></i><span className="d-none-xs">Photo / Vidéo</span></a>
                    </div>
                    <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby="dropdownMenu4">
                        <div className="card-body p-0 d-flex" onClick={(e) => { e.preventDefault(); setisCameraLiveOpen(true); toggleOpen() }}>
                            <i className="feather-camera text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Caméra<span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Ouvrir la camera de mon appareil</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2" onClick={(e) => { openDeviceImages() }}>
                            <i className="feather-file text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Libraires<span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Images locales de mon appareil</span></h4>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*, video/*"
                                multiple
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>

                    {/* =================== feeelings =================== */}
                    {/* <a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-primary feather-meh me-2"></i><span className="d-none-xs">Humeur / Activity</span></a> */}
                    {/* <div className={`ms-auto pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                    <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby="dropdownMenu4">
                        <div className="card-body p-0 d-flex">
                            <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Partager le lien <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Ajouter sur mes favoris</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide Post <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                    </div> */}
                </div>
            </div>

            {isCameraOpen && (
                <Live cb={e => {
                    if (e === true) setIsCameraOpen(false)
                }} />
            )}

            {isCameraLiveOpen && <CameraLive
                cb={e => {
                    if (e !== null) {
                        // setisCameraLiveOpen(false)
                        setFiles(e)
                        setshow(true)
                    } else {
                        setisCameraLiveOpen(false)
                    }
                }}
            />}

            {show && <Postmanager
                cb={(e) => {
                    if (e === true) {
                        setshow(false);
                        cb(true)
                    }
                }}
                data={files}
                show={show}
                key={Math.random() * Date.now()}
            />}
        </>

    );
}

export default Createpost;