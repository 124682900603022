import React, { Component } from 'react';
import { useUser } from './Userchecker';
import { baseURL, endpoint } from '../Utils/const';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { call } from '../api/apicall';
import { RiUserAddLine, RiUserFollowLine } from "react-icons/ri";
import Skeleton from 'react-loading-skeleton'

export const Profilecard = () => {

    const user = useUser();
    const history = useHistory();
    const { id: asid } = useParams();
    const [_, __, id] = String(asid).split(".")
    const { getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getCoverImage } = user
    const [isloading, setisloading] = React.useState(false)
    const [isload, setisload] = React.useState(false)
    const [infos, setinfos] = React.useState({})
    const [isfr, setisfr] = React.useState(false)
    const [isfll, setisfll] = React.useState(false)

    const onLoad = async () => {
        setisloading(true)
        call.onRunExternalRQST({
            method: "GET",
            url: `/users/user/${id}`
        })
            .then(res => {
                setisloading(false)
                const { status, data: asdata } = res
                if (status === 200) {
                    const { data } = asdata
                    setinfos(data)
                }
            })
            .catch(err => {
                setisloading(false)
                toast.error("Erreur de chargement des informations !")
            })
    }

    const onFollow = async () => {

    }

    const onRequestfriendship = async () => {
        setisload(true)
        call.onRunExternalRQST({
            url: `/users/requestfrendship`,
            method: "POST",
            data: {
                id,
                current_user: getID()
            }
        })
            .then(_ => {
                const { status, data } = _
                setisload(false)
                if (status === 200) {
                    const { data: as_d } = data
                    toast.success("Démande d'amitié envoyée")
                    setisfr(true)
                } else {
                    toast.error("Une erreur vient de se produir ! réessayer un peu plus tard !")
                }
            })
            .catch(err => {
                setisload(false)
                toast.error("Une erreur vient de se produir ! réessayer un peu plus tard !")
            })
    }

    React.useEffect(() => {
        onLoad()
    }, []);

    return (
        <>
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    {isloading ? <Skeleton count={1} style={{ height: 250 }} className='h250' /> : <img src={`${baseURL}/${infos && infos['coverimage']}`} alt="cover" />}
                </div>
                <div className="card-body p-0 position-relative">
                    {isloading ? <Skeleton count={4} style={{ marginLeft: 20, width: "95%" }} /> : (
                        <>
                            <figure className="avatar position-absolute w100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                <img src={`${baseURL}/${infos && infos['avatar']}`} alt="avatar" className="float-right p-1 bg-white rounded-circle w-100" />
                            </figure>
                            <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{infos && infos['nom']} {infos && infos['postnom']} {infos && infos['prenom']}<span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{infos && infos['email']}</span></h4>
                            <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                                {isfll ? <></> : (
                                    <a
                                        href="#"
                                        className="d-none d-lg-block bg-grey p-3 z-index-1 rounded-3 text-dark font-xsssss fw-700 ls-3"
                                        onClick={e => {
                                            e.preventDefault()
                                            onFollow()
                                        }}
                                    >
                                        <RiUserFollowLine size={20} />
                                        {" "}
                                        Suivre
                                    </a>
                                )}
                                {isfr ? <></> : (
                                    <a
                                        hidden={isfr}
                                        href="#"
                                        className="d-none d-lg-block bg-blue-gradiant p-3 z-index-1 rounded-3 text-white font-xsssss fw-700 ls-3 ml-2"
                                        style={{ marginLeft: 10 }}
                                        onClick={e => {
                                            e.preventDefault()
                                            onRequestfriendship()
                                        }}
                                    >
                                        <RiUserAddLine size={20} />
                                        {" "}
                                        Démande d'ami
                                    </a>
                                )}
                                <a href="/defaultemailbox" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-mail font-md"></i></a>
                                <a href="#" id="dropdownMenu4" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more font-md tetx-dark"></i></a>
                                <div className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg" aria-labelledby="dropdownMenu4">
                                    <div className="card-body p-0 d-flex">
                                        <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Save Link <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Add this to your saved items</span></h4>
                                    </div>
                                    <div className="card-body p-0 d-flex mt-2">
                                        <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Hide Post <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                                    </div>
                                    <div className="card-body p-0 d-flex mt-2">
                                        <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                                    </div>
                                    <div className="card-body p-0 d-flex mt-2">
                                        <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                        <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                        <li className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">Informations</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Adhésion</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Discussions</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Vidéos</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Groupes</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Evenements</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Medias</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}