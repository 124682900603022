import * as React from 'react'
import { baseURL } from '../Utils/const'
import { call } from '../api/apicall'
import toast from 'react-hot-toast'

export const FriendComponent = ({ data, cb }) => {
    const { avatar, nom, postnom, prenom, email, friends, id } = data
    const [hidden, sethidden] = React.useState(false)

    const handLeAccept = async (e) => {
        e.preventDefault()
        call.onRunExternalRQST({
            url: `/users/user/requestfriendship`,
            method: "POST",
            data: {
                id,
                status: 1
            }
        })
            .then(done => {
                const { data: asdata, status } = done
                if (status === 200) {
                    toast.success(`Vous êtes maintenant ami avec ${nom} ${postnom}`)
                    cb(true)
                } else {
                    cb(false)
                }
            })
            .catch(err => {
                cb(false)
            })
    }

    const handLeReject = async (e) => {
        e.preventDefault()
        sethidden(true)
        call.onRunExternalRQST({
            url: `/users/user/requestfriendship`,
            method: "POST",
            data: {
                id,
                status: 2
            }
        })
            .then(done => {
                const { data: asdata, status } = done
                if (status === 200) {
                    cb(true)
                } else {
                    cb(false)
                }
            })
            .catch(err => {
                cb(false)
            })
    }

    return (
        <div className="wrap" key={992 * Math.random()} hidden={hidden}>
            <div className="card-body d-flex pt-0 ps-4 pe-4 pb-0 bor-0">
                <figure className="avatar me-3">
                    <img src={`${baseURL}/${avatar}`} alt="avatar" className="shadow-sm rounded-circle w45 border-4" />
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">{nom} {postnom}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{friends} en commun</span>
                </h4>
            </div>
            <div className="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                <a href="#" onClick={handLeAccept} className="p-2 lh-20 w100 bg-blue-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Confirmer</a>
                <a href="#" onClick={handLeReject} className="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-xl">Supprimer</a>
            </div>
        </div>
    )
}