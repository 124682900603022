import React, { Component, Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/Group';
import Events from '../components/Events';
import Createpost from '../components/Createpost';
import Memberslider from '../components/Memberslider';
import Friendsilder from '../components/Friendsilder';
import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import { onRunExternalRQST } from "../api/apicall";
import toast from "react-hot-toast";
import { randomLongNumber } from "../api/helpers";
import { Feeds } from "../components/Feed";
import { useLocation, useParams, useHistory } from "react-router-dom";

export const Home = () => {
    const [l, setl] = React.useState(false)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectParam = searchParams.get('_redirect');

    // Perform any necessary actions based on the value of `_redirect`
    if (redirectParam === '1' || redirectParam === 1) {
        window.location.reload()
    }

    React.useEffect(() => {

    }, []);

    return (
        <Fragment>
            <Header />
            {/* <Leftnav /> */}
            {/* <Rightchat /> */}

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row feed-body">
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <>
                                    {/* <Storyslider /> */}
                                    <Createpost
                                        cb={e => {
                                            if (e === true) {
                                                setl(true)
                                            }
                                        }}
                                    />
                                </>
                                <>
                                    <Feeds
                                        cb={l}
                                    />
                                </>
                                {/* <Memberslider /> */}
                                {/* <Postview id="35" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                                {/* <Friendsilder /> */}
                                {/* <Postview id="36" postvideo="" postimage="post.png" avater="user.png" user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                                {/* <Load /> */}
                            </div>
                            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                <Friends />
                                <Contacts />
                                {/* <Group /> */}
                                {/* <Events /> */}
                                {/* <Profilephoto /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Popupchat /> */}
            <Appfooter />
        </Fragment>
    );
}

export default Home;