import React, { Fragment, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { UserContext, useUser } from './Userchecker';
import { baseURL } from '../Utils/const';
import { useHistory } from 'react-router-dom';
import { getFileType } from '../api/helpers';
import Load from './Load';
import { Colors } from '../Utils/styles';
import { call } from '../api/apicall';
import toast from 'react-hot-toast';

export const Postmanager = ({ data, cb, show }) => {
    const user = useUser();
    const history = useHistory();
    const [isloading, setisloading] = React.useState(false)
    const { getAdresse, getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getCoverImage } = user
    const [temps, settemps] = React.useState([...data])
    const messageRef = React.useRef(null);

    const handleDelete = (index) => {
        const newData = [...temps];
        newData.splice(index, 1);
        settemps(newData);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const message = messageRef.current.value.trim();
        if (!message) return messageRef.current.style.border = '2px solid red';

        messageRef.current.style.border = '1px solid #ced4da'; // Reset to default border color
        // form.append("content", message)
        // form.append("typepost", 1) //ie. original post
        // form.append("from_id", 0)
        // form.append("from_id_post", 0)

        const files = {}
        Array.from(data).map((file, index) => {
            files[`file_${index}`] = file
        })
        setisloading(true)
        call.onRunExternalRQST({
            data: {
                content: message,
                typepost: 1,
                from_id: 0,
                from_id_post: 0,
                ...files
            },
            method: "POST",
            multer: true,
            url: "/posts/post/add"
        })
            .then(res => {
                setisloading(false);
                const { status, message, data } = res
                if (status === 200) {
                    e.target.reset()
                    toast.success("Post envoyé avec succès")
                    cb(true)
                } else {
                    toast.error("Une erreur vient de se produire, veullez réessayer un peu plus tard !")
                    cb(false)
                }
            })
            .catch(err => {
                setisloading(false)
                toast.error("Une erreur vient de se produire, veullez réessayer un peu plus tard !")
                cb(false)
            })
    }

    React.useEffect(() => {
    }, []);

    return (
        <>
            <Fragment>
                <div className="overlay">
                    <div className="modal-content">
                        <div className="row">
                            <div className="col-lg-12- col-12">
                                <div
                                    className="card mb-0  overflow-hidden chat-wrapper bg-image-center bg-image-cover pt-2"
                                    style={{ position: 'relative', height: 210 }}
                                >
                                    <div className='row g-2'>
                                        {temps.map((_, index) => {
                                            const ext = getFileType({ fileName: _['name'] })
                                            const file = URL.createObjectURL(_);
                                            switch (ext) {
                                                case 1:
                                                    return (
                                                        <div className='col-lg-2 d-flex align-items-center justify-content-center position-relative' key={index}>
                                                            <img
                                                                src={file}
                                                                className='rounded shadow bg-white'
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                alt={`image-${index}`}
                                                            />
                                                            <button
                                                                onClick={() => handleDelete(index)}
                                                                className='btn btn-danger btn-sm'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '5px',
                                                                    right: '5px',
                                                                    borderRadius: '50%',
                                                                    padding: '4px 8px',
                                                                    lineHeight: '1',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    );
                                                    break;
                                                case 2:
                                                    return (
                                                        <div className='col-lg-2 d-flex align-items-center justify-content-center position-relative' key={index}>
                                                            <video
                                                                src={file}
                                                                className='rounded shadow bg-white'
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                                alt={`video-${index}`}
                                                                type="video/mp4"
                                                                controls
                                                            // autoPlay
                                                            />
                                                            <button
                                                                onClick={() => handleDelete(index)}
                                                                className='btn btn-danger btn-sm'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '5px',
                                                                    right: '5px',
                                                                    borderRadius: '50%',
                                                                    padding: '4px 8px',
                                                                    lineHeight: '1',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    );
                                                    break;
                                                default:
                                                    break;
                                            }
                                        })}
                                    </div>
                                </div>
                                <form onSubmit={(e) => { handleSubmit(e) }}>
                                    <div className="card-body p-0 mt-3 position-relative">
                                        <figure className="avatar position-absolute ms-2 mt-1 top-5">
                                            <img src={`${baseURL}/${getAvatar()}`} alt="icon" className="shadow-sm rounded-circle w30" />
                                        </figure>
                                        <textarea
                                            name="message"
                                            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500- fw-500 border-light-md theme-dark-bg"
                                            cols="30"
                                            rows="10"
                                            placeholder={`${getNom()} ${getPostnom()}, c'est quoi la motivation aujourd'hui ?`}
                                            ref={messageRef}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                // messageRef.current.style.background = Colors.secondaryColor
                                                messageRef.current.style.fontSize = 23
                                                messageRef.current.style.color = "black"
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-1">
                                        {isloading ? (
                                            <Load />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                                            >
                                                Publier
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default Postmanager;
