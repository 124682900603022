import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Headerauth } from "../components/Authheader";
import bg from "../assets/Images/auth.png";
import { appPrivancy } from "../Utils/const";
import { onRunExternalRQST } from "../api/apicall";
import Load from "../components/Load";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { routes } from "../api/routes";
import { UserContext } from "../components/Userchecker";
import { useHistory } from "react-router-dom";
import { Authwithgoogle } from "../components/Authwithgoogle";

const validationSchema = Yup.object({
    username: Yup.string()
        .matches(/^[a-z]+ [a-z]+$/i, "Le nom doit être au format 'Prénom Nom' combiner les deux noms séparés par un espace !")
        .required("Le nom d'utilisateur est requis."),
    // nom: Yup.string().required("Le nom est requis."),
    // postnom: Yup.string().required("Le postnom est requis."),
    email: Yup.string().email("L'email n'est pas valide.").required("L'email est requis."),
    password: Yup.string().min(6, "Le mot de passe doit contenir au moins 6 caractères").required("Le mot de passe est requis."),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Les mots de passe ne correspondent pas.")
        .required("Veuillez confirmer votre mot de passe."),
    acceptTerms: Yup.boolean()
        .oneOf([true], "Vous devez accepter les conditions d'utilisation.")
});

export const Register = () => {

    const [isloading, setisloading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const history = useHistory()
    const [error, seterror] = React.useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const formik = useFormik({
        initialValues: {
            // nom: "",
            username: "",
            // postnom: "",
            email: "",
            genre: "",
            password: "",
            confirmPassword: "",
            acceptTerms: false
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { username } = values
            const nom = String(username).substring(0, username.indexOf(" "))
            const postnom = String(username).substring(username.indexOf(" ") + 1)
            setisloading(true)
            onRunExternalRQST({
                data: { ...values, nom, postnom, username: String("@").concat(username).split(" ").join("") },
                method: "POST",
                url: "/users/user/signup"
            })
                .then(res => {
                    setisloading(false)
                    const { status, message, data } = res
                    switch (status) {
                        case 200:
                            // const { user, token } = data
                            // toast.success("Compte crée avec succès !");
                            history.push({
                                pathname: routes['verify'],
                                state: { ...data.data }
                            })
                            break;
                        case 507:
                            const { error, duplicateColumns } = data['data'];
                            const lines = Object.values(duplicateColumns).join(" , ")
                            toast.error("Les informations que vous avez entrées sont déjà prises !")
                            toast.error("Utilisez vos propres informations personnelles")
                            toast.error(`Ces informations ${lines} sont déjà utilisés par quelqu'un d'autre`, { duration: 10000 })
                            break
                        default:
                            toast.error("Une erreur vient de se produire lors du traitement !")
                            break;
                    }
                })
                .catch(err => {
                    setisloading(false)
                    toast.error("Une erreur vient de se produire lors du traitement !")
                })
        }
    });

    React.useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <div className="main-wrap">
                <Headerauth />
                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-4">Créer <br />votre compte</h2>

                                <form onSubmit={formik.handleSubmit}>

                                    {/* Username */}
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                        <input
                                            type="text"
                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                            placeholder="Nom d'utilisateur"
                                            name="username"
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.username && formik.errors.username ? (
                                            <p className="text-danger">{formik.errors.username}</p>
                                        ) : null}
                                    </div>

                                    {/* Nom */}
                                    {/* <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                        <input
                                            type="text"
                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                            placeholder="Nom"
                                            name="nom"
                                            value={formik.values.nom}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.nom && formik.errors.nom ? (
                                            <p className="text-danger">{formik.errors.nom}</p>
                                        ) : null}
                                    </div> */}

                                    {/* Postnom */}
                                    {/* <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                        <input
                                            type="text"
                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                            placeholder="Postnom"
                                            name="postnom"
                                            value={formik.values.nom}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.postnom && formik.errors.postnom ? (
                                            <p className="text-danger">{formik.errors.postnom}</p>
                                        ) : null}
                                    </div> */}

                                    {/* Email */}
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                        <input
                                            type="text"
                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                            placeholder="Votre adresse mail"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.email && formik.errors.email ? (
                                            <p className="text-danger">{formik.errors.email}</p>
                                        ) : null}
                                    </div>

                                    {/* Mot de passe */}
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                            placeholder="Mot de passe"
                                            name="password"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span
                                            className={`font-sm ${!showPassword ? "ti-eye" : "feather-eye-off"} text-grey-500 pe-0 pointer`}
                                            style={{ position: "absolute", right: "15px", top: "19px" }}
                                            onClick={e => {
                                                e.preventDefault()
                                                togglePasswordVisibility()
                                            }}
                                        />
                                        {formik.touched.password && formik.errors.password ? (
                                            <p className="text-danger">{formik.errors.password}</p>
                                        ) : null}
                                    </div>

                                    {/* Confirmation mot de passe */}
                                    <div className="form-group icon-input mb-1">
                                        <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                            placeholder="Confirmation de mot de passe"
                                            name="confirmPassword"
                                            value={formik.values.confirmPassword}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span
                                            className={`font-sm ${!showPassword ? "ti-eye" : "feather-eye-off"} text-grey-500 pe-0 pointer`}
                                            style={{ position: "absolute", right: "15px", top: "19px" }}
                                            onClick={e => {
                                                e.preventDefault()
                                                togglePasswordVisibility()
                                            }}
                                        />
                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                            <p className="text-danger">{formik.errors.confirmPassword}</p>
                                        ) : null}
                                    </div>

                                    {/* Conditions d'utilisation */}
                                    <div className="form-check text-left mb-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input mt-2"
                                            id="exampleCheck2"
                                            name="acceptTerms"
                                            checked={formik.values.acceptTerms}
                                            onChange={formik.handleChange}
                                        />
                                        <label className="form-check-label font-xsss text-grey-500">
                                            J'accepte <a target="_blank" href={appPrivancy} className="fw-700 ms-1">les conditions</a> d'utilisations
                                        </label>
                                        {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                                            <p className="text-danger">{formik.errors.acceptTerms}</p>
                                        ) : null}
                                    </div>

                                    {/* Bouton d'envoi */}
                                    <div className="col-sm-12 p-0 text-left">
                                        <div className="form-group mb-1">
                                            {isloading ?
                                                (
                                                    <Load />
                                                )
                                                :
                                                (
                                                    <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                                        Créer un compte
                                                    </button>
                                                )
                                            }
                                        </div>
                                        <div className="form-group mb-1" hidden={isloading}>
                                            <Authwithgoogle
                                                text={"signup_with"}
                                                context={2}
                                                cb={_ => {
                                                    if (_ !== false) {
                                                        const { given_name, family_name, name, picture, email, email_verified } = _
                                                        formik.setFieldValue('email', email);
                                                        formik.setFieldValue('username', name);
                                                        formik.handleSubmit()
                                                    }
                                                }}
                                            />
                                        </div>
                                        <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                            Vous avez déjà un compte ? <Link to={routes['signin']} className="fw-700 ms-1">Connexion</Link>
                                        </h6>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
};

export default Register;
