import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import { useLocation } from "react-router-dom";
import { Headerauth } from "../components/Authheader";
import bg from "../assets/Images/auth.png";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { call } from "../api/apicall";
import toast from "react-hot-toast";
import { routes } from "../api/routes";
import { useHistory } from "react-router-dom";
import { retrieveSession, saveSession } from "../api/session";

const VerificationSchema = Yup.object().shape({
    code: Yup.array()
        .of(Yup.string().length(1, "Entrer un seul caractèr pour chaque case"))
        .required("Le code de vérification est requis !")
});

export const Verify = () => {

    const location = useLocation();
    const data = location.state;
    const length = 6
    const { nom, postnom, email, id } = data || { nom: "David", postnom: "Maene", email: "hello@davidmaene.com", id: 9920 }
    const [isloading, setisloading] = React.useState(false)
    const inputRefs = React.useRef([]);
    const [codes, setcodes] = React.useState([])
    const history = useHistory()

    const handleInputChange = (e, index, handleChange) => {
        handleChange(e);
        if (e.target.value.length === 1 && index < inputRefs.current.length - 1) {
            // Move to next input if a value is entered
            inputRefs.current[index + 1].focus();
        } else if (e.target.value === "" && index > 0) {
            // Move to previous input if value is deleted
            inputRefs.current[index - 1].focus();
        }
    };

    const handleResendCode = async (e) => {
        e.preventDefault()
        setisloading(true)
        call.onRunExternalRQST({
            method: "POST",
            url: `/users/user/resendcode`,
            data: {
                phone: email,
                email
            }
        })
            .then(__ => {
                setisloading(false)
                const { message, status, data } = __
                if (status === 200) {
                    toast(`Un nouveau code de vériification a été envoyé à ${email}`)
                } else {
                    toast.error("Une erreur vient de se produire lors du renvoie du code de vérification !")
                }
            })
            .catch(err => {
                setisloading(false)
                console.log('====================================');
                console.log(err);
                console.log('====================================');
                toast.error("Une erreur vient de se produire lors du renvoie du code de vérification !")
            })
    };

    const handleVerifyAccount = async ({ code }) => {
        setisloading(true)
        call.onRunExternalRQST({
            method: "POST",
            url: `/users/user/verify`,
            data: {
                email,
                phone: email,
                code
            }
        })
            .then(__ => {
                setisloading(false)
                const { message, status, data } = __
                switch (status) {
                    case 200:
                        const { data: as_data } = data;
                        const { user, token, reftoken } = as_data
                        saveSession({
                            currentUser: { ...user, token, reftoken },
                            rememberMe: true,
                            callBack: (e, d) => {
                                if (d) {
                                    retrieveSession({
                                        callBack: (eR, dR) => {
                                            if (dR) {
                                                toast.success(`L'adresse ${email} a été bien vérifié ! `)
                                                window.location.replace(`#${routes['home']}?_redirect=1`)
                                                // history.replace(routes['home']);
                                            } else {
                                                toast.error("Une erreur de connexion vient de se produire, réessayer un peu plus tard !", {
                                                    position: "bottom-center",
                                                    duration: 10
                                                });
                                            }
                                        }
                                    })

                                } else {
                                    toast.error("Une erreur de connexion vient de se produire ", {
                                        position: "bottom-center",
                                        duration: 10
                                    });
                                }
                            }
                        })
                        break;
                    case 203:
                        toast.error(`Le code ${code} que vous avez utilisé est invalide `)
                        break;
                    case 400:
                        toast.error(`Cette adresse est déjà vérifié connectew vous plutôt !`)
                        break;
                    default:
                        toast.error("Une erreur vient de se produire lors du renvoie du code de vérification !")
                        break;
                }

            })
            .catch(err => {
                setisloading(false)
                console.log('====================================');
                console.log(err);
                console.log('====================================');
                toast.error("Une erreur vient de se produire lors du renvoie du code de vérification !")
            })
    };

    React.useEffect(() => {
        console.log('====================================');
        console.log(data);
        console.log('====================================');
    }, []);

    return (
        <Fragment>
            <div className="main-wrap">
                <Headerauth />
                <div className="row">
                    <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-4">Vérification <br />adresse mail</h2>
                                <p>Bonjour <a className="fw-700 ms-1" onClick={_ => _.preventDefault()}>{nom} {postnom}</a>, vous êtes à un pas pour finaliser la création de votre compte, nous avons envoyer un code de {length} à l'adresse mail <a href="#" className="fw-700 ms-1" onClick={_ => _.preventDefault()}>{email}</a> </p>
                                <Formik
                                    initialValues={{ code: Array(6).fill("") }}
                                    validationSchema={VerificationSchema}
                                    onSubmit={(values) => {
                                        const code = values.code.join("");
                                        handleVerifyAccount({ code })
                                    }}
                                >
                                    {({ errors, touched, handleChange }) => (
                                        <Form>
                                            <div className="form-group d-flex justify-content-between mb-2">
                                                {Array.from({ length }).map((_, index) => (
                                                    <div key={index} className="mx-1">
                                                        <Field
                                                            name={`code[${index}]`}
                                                            type="number"
                                                            maxLength="1"
                                                            innerRef={(el) => (inputRefs.current[index] = el)}
                                                            className={`form-control`} // ${errors.code && touched.code ? 'is-invalid' : ''}
                                                            style={{
                                                                width: '50px',
                                                                textAlign: 'center',
                                                                MozAppearance: 'textfield',
                                                                WebkitAppearance: 'none',
                                                                appearance: 'none'
                                                            }}
                                                            onChange={(e) => handleInputChange(e, index, handleChange)}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-sm-12 p-0 text-left">
                                                <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                                    Confirmer
                                                </button>
                                            </div>
                                            <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                                Vous n'avez pas reçu de code ? <a href="#" onClick={(e) => { handleResendCode(e) }} className="fw-700 ms-1">renvoyer le code</a>
                                            </h6>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    );
}

export default Verify;