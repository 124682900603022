export const APP_MODE = "prod" 
// || "dev" || "prod"
const bases = {
    dev: "http://127.0.0.1:4601",
    prod: "https://bkpuurga.mukulima.com"
}

export const appName = "Puurga"
export const appOwner = "FASICONNECT";
export const appNumber = "+243 970 284 772"
export const appVersion = "1.0.1"
export const sessionKey = "_userpuurga___";
export const appPrivancy = "https://privacy.puurga.com/"
export const appclientidgooglesecret = "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"
export const appclientidgoogle = "229694535036-mdd85lvl2rrj0fj38epip6rmmdf47o1c.apps.googleusercontent.com"

export const prefixApp = "/api"
export const baseURL = APP_MODE === "dev" ? bases['dev'] : bases['prod']
export const endpoint = String(baseURL).concat(prefixApp)
