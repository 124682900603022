import React, { Component, Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import toast from "react-hot-toast";

export const Live = ({ cb }) => {
    const [isOpen, setisOpen] = React.useState(false);
    const [isCameraOpen, setIsCameraOpen] = React.useState(false);
    const toggleOpen = () => setisOpen(!isOpen);
    const menuClass = `${isOpen ? " show" : ""}`;
    const videoRef = React.useRef(null);
    const timerRef = React.useRef(null); // Reference for the timer
    const [timeElapsed, setTimeElapsed] = React.useState(0);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const openCamera = async (e) => {
        // e.preventDefault();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            setIsCameraOpen(true);
            // Start the timer
            timerRef.current = setInterval(() => {
                setTimeElapsed(prevTime => prevTime + 1);
            }, 1000);

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            }
        } catch (error) {
            console.error('Error accessing the camera: ', error);
        }
    };

    const closeCamera = () => {
        if (videoRef.current) {
            let tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop()); // Stop the video stream
        }
        setIsCameraOpen(false);

        clearInterval(timerRef.current);
        timerRef.current = null;
    };

    React.useEffect(() => {
        // openCamera()
        return () => {
            // Cleanup the timer on component unmount
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [])

    return (
        <Fragment>
            <div className="overlay">
                <div className="modal-content">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border-0 mb-0 rounded-3 overflow-hidden chat-wrapper bg-image-center bg-image-cover"
                                style={{ backgroundImage: `url("https://via.placeholder.com/975x700.png")`, position: 'relative' }}>
                                <div className="card-body position-absolute mt-0 ms-0 left-0 d-flex justify-content-center" style={{ width: '100%', height: '100%' }}>
                                    <video ref={videoRef} autoPlay style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}></video>
                                </div>
                                <div className="card-body text-center p-2 position-absolute w-100 bottom-0 bg-gradiant-bottom">
                                    <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); toast.error("Non pris en charge pour le moment!") }} className="btn-round-xl d-md-inline-block d-none bg-blur m-3 me-0 z-index-1"><i className="feather-grid text-white font-md"></i></a>
                                    <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); toast.error("Non pris en charge pour le moment!") }} className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1"><i className="feather-mic-off text-white font-md"></i></a>
                                    <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); isCameraOpen ? closeCamera() : openCamera() }} className="btn-round-xxl bg-danger z-index-1"><i className="feather-phone-off text-white font-md"></i></a>
                                    <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); toast.error("Non pris en charge pour le moment!") }} className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1"><i className="ti-video-camera text-white font-md"></i></a>
                                    <a href="/defaultlivestream" onClick={(e) => { e.preventDefault(); if(isCameraOpen) closeCamera(); return cb(true) }} className="btn-round-xl d-md-inline-block d-none bg-blur m-3 ms-0 z-index-1"><i className="ti-close text-white font-md"></i></a>
                                    <span className="p-2 bg-blur z-index-1 text-white fw-700 font-xssss rounded-3 right-15 position-absolute mb-4 bottom-0">{formatTime(timeElapsed)}</span>
                                    <span className="live-tag position-absolute left-15 mt-2 bottom-0 mb-4 bg-danger p-2 z-index-1 rounded-3 text-white font-xsssss text-uppersace fw-700 ls-3">En direct</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Live;