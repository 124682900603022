import * as React from 'react'
import { appName } from '../Utils/const'
import { routes } from '../api/routes'

export const Headerauth = () => {
    return (
        <div className="nav-header bg-transparent shadow-none border-0">
            <div className="nav-top w-100">
                <a href="#">
                    <i className="icon-puurga text-white display1-size me-2 ms-0 logo-text-s"></i>
                    <span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0 text-white logo-text-s">{appName} </span>
                </a>
                <button className="nav-menu me-0 ms-auto"></button>

                <a href={`#${routes['signin']}`} className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100- text-center lh-20 rounded-xl">Connexion</a>
                <a href={`#${routes['signup']}`} className="header-btn d-none d-lg-block bg-blue-gradiant fw-500 text-white font-xsss p-3 ms-2 w100- text-center lh-20 rounded-xl">Créer un compte</a>
            </div>
        </div>
    )
}