import React, { Component } from 'react';
import { useUser } from './Userchecker';
import { baseURL, endpoint } from '../Utils/const';
import toast from 'react-hot-toast';
import { detroySession, updateSession } from '../api/session';
import { routes } from '../api/routes';
import { useHistory } from 'react-router-dom';
import { IoIosLogOut } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { call } from '../api/apicall';

export const ProfilecardThree = () => {

    const user = useUser();
    const history = useHistory();
    const [file, setfile] = React.useState(null)
    const [filecover, setfilecover] = React.useState(null)
    const { getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getCoverImage } = user
    const fileInputRef = React.useRef(null);
    const fileInputRefCover = React.useRef(null);

    const openDeviceImagesForAvatar = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        } else {
            toast.error("Erreur de référencement dans le formulaire !")
            console.error("File input reference is not available.");
        }
    };

    const openDeviceImagesCover = () => {
        if (fileInputRefCover.current) {
            fileInputRefCover.current.click();
        } else {
            toast.error("Erreur de référencement dans le formulaire !")
            console.error("File input reference is not available.");
        }
    };

    const handleFileChangeAvatar = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setfile(URL.createObjectURL(selectedFile))
            call.onRunExternalRQST({
                data: {
                    avatar: selectedFile,
                    iduser: getID()
                },
                multer: true,
                method: "PUT",
                url: "/users/user/updateavatar"
            })
                .then(_ => {
                    const { status, data: datat } = _
                    if (status === 200) {
                        const { data } = datat
                        const { avatar } = data
                        updateSession({
                            key: 'avatar',
                            value: avatar,
                            callBack: (err, done) => {
                                console.log("Saving new infos ==> ",done);
                            }
                        })
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    const handleFileChangeCover = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setfile(URL.createObjectURL(selectedFile))
            call.onRunExternalRQST({
                data: {
                    cover: selectedFile,
                    iduser: getID()
                },
                multer: true,
                method: "PUT",
                url: "/users/user/updatecover"
            })
            .then(_ => {
                const { status, data: datat } = _
                if (status === 200) {
                    const { data } = datat
                    const { coverimage } = data
                    updateSession({
                        key: 'coverimage',
                        value: coverimage,
                        callBack: (err, done) => {
                            console.log("Saving new infos ==> ",done);
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    };

    React.useEffect(() => {
    }, []);

    return (
        <>
            <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                    {/* filecover ? filecover :  */}
                    <img src={`${baseURL}/${getCoverImage()}`} alt="cover" onClick={(e) => { e.preventDefault(); openDeviceImagesCover() }} />
                    <input
                        type="file"
                        ref={fileInputRefCover}
                        style={{ display: 'none' }}
                        accept="image/*"
                        multiple
                        onChange={handleFileChangeCover}
                    />
                </div>
                <div className="card-body p-0 position-relative">
                    <figure className="avatar position-absolute w100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                        <div style={{ position: "absolute", bottom: 0, right: -10 }}>
                            <FaEdit
                                size={23}
                                className='pointer'
                                onClick={e => {
                                    openDeviceImagesForAvatar()
                                }}
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                multiple
                                onChange={handleFileChangeAvatar}
                            />
                        </div>
                        {/* file ? file :  */}
                        <img src={`${baseURL}/${getAvatar()}`} alt="avatar" className="float-right p-1 bg-white rounded-circle w-100" />
                    </figure>
                    <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{getNom()} {getPostnom()} {getPrenom()}<span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{getEmail()}</span></h4>
                    <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                        <a
                            href="#"
                            className="d-none d-lg-block bg-grey p-3 z-index-1 rounded-3 text-dark font-xsssss fw-700 ls-3"
                            onClick={e => {
                                e.preventDefault()
                                detroySession({
                                    callBack: (err, done) => {
                                        if (done) {
                                            toast.success("Déconnexion réussie !")
                                            history.replace(routes['signin'])
                                        } else {
                                            toast.error("Une erreur vient de se produir lors de la déconnexion !")
                                        }
                                    }
                                })
                            }}
                        >
                            <IoIosLogOut size={20} />
                            {" "}
                            Déconnexion
                        </a>
                        {/* <a href="/defaultemailbox" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-mail font-md"></i></a> */}
                        {/* <a href="/home" id="dropdownMenu4" className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="ti-more font-md tetx-dark"></i></a> */}
                        <div className="dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg" aria-labelledby="dropdownMenu4">
                            <div className="card-body p-0 d-flex">
                                <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Save Link <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Add this to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Hide Post <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-0">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-0">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                        <li className="active list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">Mes informations</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Adhésion</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Discussions</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Vidéos</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Groupes</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Evenements</a></li>
                        <li className="list-inline-item me-5"><a className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#" onClick={e => { e.preventDefault(); toast("non pris en charge pour le moment") }} data-toggle="tab">Medias</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ProfilecardThree;