import React, { Component } from 'react';
import { call } from '../api/apicall';
import { Link } from 'react-router-dom';
import { routes } from '../api/routes';
import { baseURL } from '../Utils/const';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';


export const Contacts = () => {
    const [contactList, setcontactList] = React.useState([]);
    const [isloading, setisloading] = React.useState(false);
    const history = useHistory()
    const limit = 10

    const onLoad = async () => {
        setisloading(true)
        call.onRunExternalRQST({
            url: "/users/listall",
            method: "GET"
        })
            .then(done => {
                setisloading(false)
                const { data: asdata, status } = done
                if (status === 200) {
                    const { data } = asdata
                    const { list } = data
                    setcontactList(Array.from(list).slice(0, limit))
                } else {
                    setcontactList([])
                }
            })
            .catch(err => {
                setcontactList()
            })
    }

    React.useEffect(() => {
        onLoad()
    }, []);

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex align-items-center p-4">
                <h4 className="fw-700 mb-0 font-xssss text-grey-900">Sugestions d'amis</h4>
                <Link to={"#"} className="fw-600 ms-auto font-xssss text-primary">Voir tout</Link>
            </div>
            {isloading ? <Skeleton count={5} style={{ width: "93%", marginLeft: 10, marginBottom: 20, height: 60 }} /> : (
                <>
                    {contactList.map((value, index) => {
                        const { id, nom, postnom, avatar } = value
                        const link = `${routes['member']}/${Date.now()}.${Date.now()}.${id}.${Date.now()}.${Date.now()}`
                        return (
                            <div
                                key={index}
                                className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3 pointer"
                            >
                                <figure className="avatar me-2 mb-0" onClick={_ => { history.push(link); _.preventDefault() }}>
                                    <img src={`${baseURL}/${avatar}`} alt="avater" className="shadow-sm rounded-circle w45" />
                                </figure>
                                <h4 className="fw-700 text-grey-900 font-xssss mt-2" onClick={_ => { history.push(link); _.preventDefault() }}>
                                    {nom} {postnom}
                                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{0} en commun</span>
                                </h4>
                                <a href={link} className="btn-round-sm bg-blue-gradiant ms-auto mt-2"><span className="feather-chevron-right font-xss text-white"></span></a>
                            </div>
                        )
                    })}
                </>
            )}
        </div>
    );
}

export default Contacts;