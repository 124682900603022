import React, { Component, Fragment } from "react";
import Header from '../components/Header';
import { TbError404 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { routes } from "../api/routes";
import { Colors } from "../Utils/styles";

class Notfound extends Component {
    render() {
        return (
            <Fragment>
                {/* <Header /> */}
                <div className="main-content pt-0 bg-white ps-0 pe-0">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8 text-center default-page vh-100 align-items-center d-flex">
                                <div className="card border-0 text-center d-block p-0">
                                    <TbError404 size={150} color={Colors.primaryColor} />
                                    <h1 className="fw-700 text-grey-900 display3-size display4-md-size">Oops! On dirait que vous êtes perdu.</h1>
                                    <p className="text-grey-500 font-xsss">La page que vous recherchez n'est pas disponible. Essayez de rechercher à nouveau ou utilisez le bouton Aller à.</p>
                                    <Link to={routes['home']} className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3">
                                        Page d'acceuil
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Notfound;