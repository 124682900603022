import * as React from 'react'
import { GoogleOAuthProvider, GoogleLogin, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { appclientidgoogle } from '../Utils/const';
import { jwtDecode } from 'jwt-decode';
import toast from 'react-hot-toast';
import { call } from '../api/apicall';
import Load from './Load';
import { retrieveSession, saveSession } from '../api/session';
import { routes } from '../api/routes';
import { useHistory } from 'react-router-dom';

export const Authwithgoogle = ({ text, context, cb }) => {

    const [isloading, setisloading] = React.useState(false)
    const history = useHistory()

    const handleLoginSuccess = (response) => {
        const { credential, clientId, select_by } = response;
        const dec = jwtDecode(credential);
        const { given_name, family_name, name, picture, email, email_verified } = dec
        setisloading(true)
        switch (context) {
            case 1:
                call.onRunExternalRQST({
                    data: { ...dec },
                    method: "POST",
                    url: "/users/user/oauth",
                })
                    .then(res => {
                        setisloading(false)
                        const { message, data, status } = res
                        switch (status) {
                            case 200:
                                toast.success("Authentification réussie !")
                                const { data: as_data } = data;
                                const { user, token, reftoken } = as_data
                                saveSession({
                                    currentUser: { ...user, token, reftoken },
                                    rememberMe: true,
                                    callBack: (e, d) => {
                                        if (d) {
                                            retrieveSession({
                                                callBack: (eR, dR) => {
                                                    if (dR) {
                                                        googleLogout()
                                                        window.location.replace(`#${routes['home']}?_redirect=1`)
                                                        // history.replace(routes['home']);
                                                    } else {
                                                        toast.error("Une erreur de connexion vient de se produire, réessayer un peu plus tard !", {
                                                            position: "bottom-center",
                                                            duration: 10
                                                        });
                                                    }
                                                }
                                            })

                                        } else {
                                            toast.error("Une erreur de connexion vient de se produire ", {
                                                position: "bottom-center",
                                                duration: 10
                                            });
                                        }
                                    }
                                })
                                break;
                            case 203:
                                toast.error("Mot de passe ou nom d'utilisateur est incorrect !", { duration: 10 })
                                break;
                            default:
                                toast.error("Une erreur vient de se produire lors du traitement !")
                                break;
                        }
                    })
                    .catch(error => {
                        setisloading(false)
                        toast.error("Une erreur vient de se produir lors de l'authentification avec google !, vueillez réessayer un peu plus tard !")
                    })
                break;
            case 2:
                setisloading(false)
                return cb(dec)
                call.onRunExternalRQST({
                    data: { ...dec },
                    method: "POST",
                    url: "/users/user/signup",
                })
                    .then(res => {
                        setisloading(false)
                        const { message, data, status } = res
                        switch (status) {
                            case 200:
                                // toast.success("Authentification réussie !")
                                const { data: as_data } = data;
                                cb(as_data)
                                break;
                            case 203:
                                toast.error("Mot de passe ou nom d'utilisateur est incorrect !")
                                cb(false)
                                break;
                            default:
                                toast.error("Une erreur vient de se produire lors du traitement !")
                                cb(false)
                                break;
                        }
                    })
                    .catch(err => {
                        toast.error("Une erreur vient de se produire lors du traitement !")
                        cb(false)
                    })

                break;
            default:
                toast.error("Une erreur vient de se produir lors de l'authentification avec google !, vueillez réessayer un peu plus tard !")
                cb(false)
                break;
        }
    };

    const handleLoginFailure = (error) => {
        // console.error("This is the error message", error); // Traite l'erreur ici
        toast.error("Une erreur vient de se produir lors de l'authentification avec google !, vueillez réessayer un peu plus tard !")
    };

    // const login = useGoogleLogin({
    //     onSuccess: response => handleLoginSuccess(response),
    //     onFailure: error => handleLoginFailure(error)
    // });

    return (
        <GoogleOAuthProvider clientId={appclientidgoogle}>
            <div style={{}} className='mt-4'>
                {isloading ? <Load /> : (
                    <GoogleLogin
                        theme='filled_blue'
                        key={99203 * Math.random()}
                        onSuccess={handleLoginSuccess}
                        onFailure={handleLoginFailure}
                        text={text}
                        size='large'
                        width={345}
                        shape='circle'
                    // useOneTap
                    />
                )}
            </div>
        </GoogleOAuthProvider>
    );
}