import React, { Component, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Darkbutton from '../components/Darkbutton';
import { appName, baseURL } from '../Utils/const';
import { UserContext, useUser } from './Userchecker';
import { routes } from '../api/routes';
import toast from 'react-hot-toast';
import { call } from '../api/apicall';
import { handleSearch } from "dm-handlesearch";
import { FriendComponent } from './Friend';
import { useHistory } from 'react-router-dom';

export const Header = () => {

    const user = useUser();
    const [isOpen, setisOpen] = React.useState(false)
    const [isActive, setisActive] = React.useState(false)
    const [isNoti, setisNoti] = React.useState(false)
    const { getAvatar, getCreatedon, getCredits, getEmail, getExtras, getGenre, getID, getLevel, getNom, getPhone, getPostnom, getPrenom, getRefreshToken, getRoles, getToken, getUserStatus, getCoverImage } = user
    const [suggestions, setsuggestions] = React.useState([])
    const [isloading, setisloading] = React.useState(false);
    const history = useHistory()

    const toggleOpen = () => setisOpen(!isOpen);
    const toggleActive = () => setisActive(!isActive);
    const toggleisNoti = () => setisNoti(!isNoti);

    const [isOpenSugg, setIsOpenSugg] = React.useState(false);
    const searchRef = React.useRef(null);

    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (value.length > 0) {
            handleSearch({
                rows: suggestions,
                columns: ['nom', 'postnom', 'prenom'],
                keyword: value,
                cb: async ({ rows, length, keyword }) => {
                    setFilteredSuggestions(rows)
                }
            })
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleOutsideClick = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setInputValue("")
            setIsOpenSugg(false);
        }
    };

    const navClass = `${isOpen ? " nav-active" : ""}`;
    const buttonClass = `${isOpen ? " active" : ""}`;
    const searchClass = `${isActive ? " show" : ""}`;
    const notiClass = `${isNoti ? " show" : ""}`;

    const onLoad = async () => {
        setisloading(true)
        call.onRunExternalRQST({
            url: "/users/listall",
            method: "GET"
        })
            .then(done => {
                setisloading(false)
                const { data: asdata, status } = done
                if (status === 200) {
                    const { data } = asdata
                    const { list } = data
                    setsuggestions(list)
                } else {
                    setsuggestions([])
                }
            })
            .catch(err => {
                setsuggestions()
            })
    }

    React.useEffect(() => {
        onLoad()
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [])

    return (
        <div className="nav-header bg-white shadow-xs border-0 bg-danger">
            <div className="nav-top justify-content-between">
                <Link to="/"><i className="icon-puurga text-primary display1-size me-3 ms-0"></i><span className="d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">{appName} </span> </Link>
                {/* <Link
                    to="#"
                    className="mob-menu ms-auto me-2 chat-active-btn"
                    onClick={e => {
                        e.preventDefault()
                        toast("Non pris en charge pour le moment !")
                    }}
                >
                    <i className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i>
                </Link> */}
                {/* <Link
                    to="#"
                    className="mob-menu me-2"
                    onClick={e => {
                        e.preventDefault()
                        toast("Non pris en charge pour le moment !")
                    }}
                >
                    <i className="feather-video text-grey-900 font-sm btn-round-md bg-greylight"></i>
                </Link> */}
                <span onClick={toggleActive} className="me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>
                <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
            </div>

            <form action="#" className="float-left header-search ms-3" ref={searchRef}>
                <div className="form-group mb-0 icon-input">
                    <i className="feather-search font-sm text-grey-400"></i>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleChange}
                        onFocus={() => setIsOpenSugg(true)}
                        placeholder="Faites une recherche ici ..."
                        className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
                    />
                </div>
                {/* ========================== */}
                {isOpenSugg && filteredSuggestions.length ? (
                    <ul className="suggestions-list bg-grey shadow position-absolute pt-3" style={{ width: 350 }}>
                        {filteredSuggestions.map((suggestion, index) => {
                            const { nom, postnom, prenom, avatar, id } = suggestion
                            const link = `${routes['member']}/${Date.now()}.${Date.now()}.${id}.${Date.now()}.${Date.now()}`
                            return (
                                <div
                                    key={index}
                                    className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3 pointer"
                                >
                                    <figure className="avatar me-2 mb-0" onClick={_ => { history.push(link); _.preventDefault() }}>
                                        <img src={`${baseURL}/${avatar}`} alt="avater" className="shadow-sm rounded-circle w45" />
                                    </figure>
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-2" onClick={_ => { history.push(link); _.preventDefault() }}>
                                        {nom} {postnom}
                                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{0} en commun</span>
                                    </h4>
                                    <a href={link} className="btn-round-sm bg-blue-gradiant ms-auto mt-2"><span className="feather-chevron-right font-xss text-white"></span></a>
                                </div>
                            )
                        })}
                    </ul>
                ) : <></>}
            </form>
            {/* <NavLink activeClassName="active" to="/home" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
            {/* <NavLink activeClassName="active" to="/defaultstorie" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-zap font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
            {/* <NavLink activeClassName="active" to="/defaultvideo" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
            {/* <NavLink activeClassName="active" to="/defaultgroup" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}
            {/* <NavLink activeClassName="active" to="/shop2" className="p-2 text-center ms-0 menu-icon center-menu-icon"><i className="feather-shopping-bag font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink> */}

            <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleisNoti}><span className="dot-count bg-warning"></span><i className="feather-bell font-xl text-current"></i></span>
            <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${notiClass}`} aria-labelledby="dropdownMenu3">
                <h4 className="fw-700 font-xss mb-4">Notifications</h4>
                {/* <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Hendrix Stamp <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 3 min <i className='icon-PNG'></i> </span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">There are many variations of pass..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Goria Coast <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 2 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Surfiya Zakir <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 1 min</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require.. </h6>
                </div>
                <div className="card bg-transparent-card w-100 border-0 ps-5">
                    <img src="assets/images/user.png" alt="user" className="w40 position-absolute left-0" />
                    <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Victor Exrixon <span className="text-grey-400 font-xsssss fw-600 float-right mt-1"> 30 sec</span></h5>
                    <h6 className="text-grey-500 fw-500 font-xssss lh-4">Mobile Apps UI Designer is require..</h6>
                </div> */}
            </div>

            {/* <Link to="/defaultmessage" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i className="feather-message-square font-xl text-current"></i></Link> */}
            {/* <Darkbutton /> */}
            <Link to={routes['profile']} className="p-0 ms-3 menu-icon col-2">
                <div className='w-75 d-flex justify-content-start flex-row align-content-center align-items-center'>
                    {/* style={{ top: '-40px', left: '30px' }} */}
                    <figure className="z-index-1 border-1 border rounded-circle" style={{ width: 40 }}>
                        <img src={`${baseURL}/${getAvatar()}`} alt="avatar" className="float-right- p-1 bg-white rounded-circle w-100" />
                    </figure>
                    <strong className='px-2 mb-2'>{getNom()} {getPostnom()}</strong>
                </div>
            </Link>

            <nav className={`navigation scroll-bar ${navClass}`}>
                <div className="container ps-0 pe-0">
                    <div className="nav-content">
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Nouveaux </span>flux</div>
                            <ul className="mb-1 top-content">
                                <li className="logo d-none d-xl-block d-lg-block"></li>
                                <li><Link to={routes['home']} className="nav-content-bttn open-font"><i className="feather-tv btn-round-md bg-blue-gradiant me-3"></i><span>Fil d'actualités</span></Link></li>
                                <li><Link to={routes['notfound']} className="nav-content-bttn open-font"><i className="feather-award btn-round-md bg-blue-gradiant me-3"></i><span>Badges</span></Link></li>
                                {/* <li><Link to="/defaultstorie" className="nav-content-bttn open-font"><i className="feather-globe btn-round-md bg-gold-gradiant me-3"></i><span>Explore Stories</span></Link></li> */}
                                <li><Link to="#" className="nav-content-bttn open-font"><i className="feather-zap btn-round-md bg-blue-gradiant me-3"></i><span>Groupes populaires</span></Link></li>
                                {/* <li><Link to="/userpage" className="nav-content-bttn open-font"><i className="feather-user btn-round-md bg-primary-gradiant me-3"></i><span>Author Profile </span></Link></li> */}
                            </ul>
                        </div>

                        {/* <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
                                <div className="nav-caption fw-600 font-xssss text-grey-500"><span>More </span>Pages</div>
                                <ul className="mb-3">
                                    <li><Link to="/defaultemailbox" className="nav-content-bttn open-font"><i className="font-xl text-current feather-inbox me-3"></i><span>Email Box</span><span className="circle-count bg-warning mt-1">584</span></Link></li>
                                    <li><Link to="/defaulthotel" className="nav-content-bttn open-font"><i className="font-xl text-current feather-home me-3"></i><span>Near Hotel</span></Link></li>
                                    <li><Link to="/defaultevent" className="nav-content-bttn open-font"><i className="font-xl text-current feather-map-pin me-3"></i><span>Latest Event</span></Link></li>
                                    <li><Link to="/defaultlive" className="nav-content-bttn open-font"><i className="font-xl text-current feather-youtube me-3"></i><span>Live Stream</span></Link></li>
                                </ul>
                            </div> */}
                        <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
                            <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Compte</div>
                            <ul className="mb-1">
                                <li className="logo d-none d-xl-block d-lg-block"></li>
                                <li onClick={e => e.preventDefault()}><Link to="#" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-settings me-3 text-grey-500"></i><span>Paramètres</span></Link></li>
                                <li onClick={e => e.preventDefault()}><Link to="#" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-pie-chart me-3 text-grey-500"></i><span>Statistiques</span></Link></li>
                                {/* <li onClick={e => e.preventDefault()}><Link to="#" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i className="font-sm feather-message-square me-3 text-grey-500"></i><span>Conversations</span><span className="circle-count bg-blue-gradiant mt-0">23</span></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <div className={`app-header-search ${searchClass}`}>
                <form className="search-form" ref={searchRef}>
                    <div className="form-group searchbox mb-0 border-0 p-1">
                        <input
                            type="text"
                            className="form-control border-0"
                            placeholder="Recherche ..."
                            value={inputValue}
                            onChange={handleChange}
                            onFocus={() => setIsOpenSugg(true)}
                        />
                        <i className="input-icon">
                            <ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>
                        </i>
                        <span className="ms-1 mt-1 d-inline-block close searchbox-close">
                            <i className="ti-close font-xs" onClick={toggleActive}></i>
                        </span>
                    </div>
                    {/* ========================== */}
                    {isOpenSugg && filteredSuggestions.length ? (
                        <ul className="suggestions-list bg-grey shadow position-absolute pt-3" style={{ width: 350 }}>
                            {filteredSuggestions.map((suggestion, index) => {
                                const { nom, postnom, prenom, avatar, id } = suggestion
                                const link = `${routes['member']}/${Date.now()}.${Date.now()}.${id}.${Date.now()}.${Date.now()}`
                                return (
                                    <div
                                        key={index}
                                        className="card-body bg-transparent-card d-flex p-3 bg-greylight ms-3 me-3 rounded-3 mb-3 pointer"
                                    >
                                        <figure className="avatar me-2 mb-0" onClick={_ => { history.push(link); _.preventDefault() }}>
                                            <img src={`${baseURL}/${avatar}`} alt="avater" className="shadow-sm rounded-circle w45" />
                                        </figure>
                                        <h4 className="fw-700 text-grey-900 font-xssss mt-2" onClick={_ => { history.push(link); _.preventDefault() }}>
                                            {nom} {postnom}
                                            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{0} en commun</span>
                                        </h4>
                                        <a href={link} className="btn-round-sm bg-blue-gradiant ms-auto mt-2"><span className="feather-chevron-right font-xss text-white"></span></a>
                                    </div>
                                )
                            })}
                        </ul>
                    ) : <></>}
                </form>
            </div>
        </div >
    );
}

export default Header;