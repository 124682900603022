import * as React from 'react'
import { onRunExternalRQST } from '../api/apicall';
import toast from 'react-hot-toast';
import { randomLongNumber } from '../api/helpers';
import Postview from './Postview';
import Load from './Load';

export const Feeds = ({ cb, id_member }) => {
    const [posts, setPosts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [hasMore, setHasMore] = React.useState(true); // Indique s'il reste des données à charger
    const [pg, setPg] = React.useState(1);
    const limit = 10;

    const __load = async (page = 1) => {
        if (isLoading) return; // Ne charge pas si déjà en cours
        setIsLoading(true);

        try {
            const { status, data } = await onRunExternalRQST({
                url: id_member ? `/posts/listallbyowner/${id_member}` : `/posts/listall`,
                method: "GET",
                params: {
                    limit: limit,
                    page: page,
                }
            });

            if (status === 200) {
                const { list, length } = data.data;
                setPosts(prevPosts => [...prevPosts, ...list]); // Ajoute les nouveaux posts aux existants
                setPg(page);
                setHasMore(list.length === limit); // Si moins de posts sont récupérés, plus de données à charger
            } else {
                toast.error("Une erreur s'est produite, veuillez réessayer plus tard !");
            }
        } catch (error) {
            toast.error("Une erreur s'est produite, veuillez réessayer plus tard !");
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        __load(1); // Charger la première page au début
    }, [cb]);

    React.useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 500 &&
                !isLoading &&
                hasMore
            ) {
                __load(pg + 1); // Charger la page suivante
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [pg, isLoading, hasMore]);

    return (
        <>
            {posts.map((post) => {
                const { id, uuid, type, user, userName, message, from, avatar, comments, retweets, likes, puurga, time, files, liked_users, purged_users, id_member } = post;
                const key = `post-${uuid}-${Math.random()}`;
                return (
                    <Postview
                        userName={userName}
                        type={type}
                        uuid={uuid}
                        id={id}
                        from={from}
                        files={files}
                        avatar={avatar}
                        user={user}
                        time={time}
                        des={message}
                        comments={comments}
                        likes={likes}
                        puurga={puurga}
                        retweets={retweets}
                        key={key}
                        askey={key}
                        liked_users={liked_users}
                        purged_users={purged_users}
                        id_member={id_member}
                    />
                );
            })}
            {isLoading && <div><Load /></div>}
            {/* {!hasMore && <div>Pas plus de posts à afficher</div>} */}
        </>
    );
};