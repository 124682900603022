import * as React from 'react'
import Load from '../components/Load'
import Appfooter from '../components/Appfooter'
import { appName } from '../Utils/const'
import { checkLifeSession, retrieveSession } from '../api/session'
// import { useHistory } from "react-router-dom";
import { routes } from '../api/routes'

export const LoaderScreen = () => {

    const history = window.location;
    const [user, setUser] = React.useState({});

    const _____loadCurrentUser = async () => {
        retrieveSession({
            callBack: (err, _user) => {
                if (_user) {
                    const { getToken } = _user;
                    const bearer = getToken();
                    checkLifeSession({
                        token: bearer,
                        callBack: (err, done) => {
                            if (done) {
                                const { code } = done;
                                if (code === 200) {
                                    setUser(_user);
                                    history.replace(`#${routes['home']}`)
                                } else {
                                    setUser(null);
                                    history.replace(`#${routes['signin']}`)
                                }
                            } else {
                                setUser(null);
                                history.replace(`#${routes['signin']}`)
                            }
                        }
                    });
                } else {
                    setUser(null);
                    history.replace(`#${routes['signin']}`)
                }
            }
        });
    };

    React.useEffect(() => {
        _____loadCurrentUser()
    }, []);

    return (
        <>
            {user
                ?
                (
                    <>
                        <div className='d-flex justify-content-center pt-10'>
                            <div>
                                <h1 className='icon-puurga text-primary text-center' style={{ fontSize: 80 }}></h1>
                                <p className='text-center' style={{ fontSize: 34 }}><strong className='text-center text-primary'>{appName}</strong></p>
                                <p className='text-center'>Chargement des informations en cours <br />patientez un moment ...</p>
                                <p className='text-center'></p>
                            </div>
                        </div >
                        <Load />
                    </>
                ) : (
                    <></>
                )}
        </>
    )
}