import React, { Component } from 'react';
import { baseURL } from '../Utils/const';
import { getFileType, maxShowLengthStringPost, truncateString } from '../api/helpers';
import { FaPlay } from "react-icons/fa";
import { Colors } from '../Utils/styles';
import { Videocomponent } from './Video';
import { ImageComponent } from './Image';
import { call } from '../api/apicall';
import { useUser } from './Userchecker';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { routes } from '../api/routes';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';

export const Postview = ({ user, time, des, avatar, postimage, postvideo, id, comments, retweets, likes, puurga, from, uuid, type, files, askey, liked_users, purged_users, id_member }) => {

    const currentUser = useUser()

    const [isOpen, setisOpen] = React.useState(false)
    const [isActive, setisActive] = React.useState(false)
    const [readmore, setreadmore] = React.useState(false)
    const [isloading, setisloading] = React.useState(false)
    const [vi, setvi] = React.useState(false)
    const menuClass = `${isOpen ? " show" : ""}`;
    const emojiClass = `${isActive ? " active" : ""}`;
    const videoRef = React.useRef(null);

    const { getID } = currentUser

    const [lks, setlks] = React.useState(likes)
    const [prgs, setprgd] = React.useState(puurga)
    const [cmmts, setcmmts] = React.useState(comments)

    const toggleOpen = () => setisOpen(!isOpen);
    const toggleActive = () => setisActive(!isActive);

    const [liked, setliked] = React.useState(Array.from(liked_users).includes(getID()))
    const [puurgad, setpuurgad] = React.useState(Array.from(purged_users).includes(getID()))
    const [showcomments, setshowcomments] = React.useState(false);
    const link = id_member === getID() ? routes['profile'] : `${routes['member']}/${Date.now()}.${Date.now()}.${id_member}.${Date.now()}.${Date.now()}`;

    const [currentImage, setCurrentImage] = React.useState(0);
    const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

    const openLightbox = (event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const onLike = async () => {
        // setisloading(true)
        setliked(!liked)
        if (liked) setlks(lks - 1)
        if (!liked) setlks(lks + 1)
        call.onRunExternalRQST({
            url: "/posts/post/like",
            method: "POST",
            data: {
                id_post: id,
                like: liked ? 2 : 1
            }
        })
            .then(_ => {
                console.log('====================================');
                console.log("Like state ==>", _['status']);
                console.log('====================================');
            })
            .catch(err => {
                console.log('====================================');
                console.log("Error on like event ==>", err);
                console.log('====================================');
            })
    }

    const onPuurga = async () => {
        setpuurgad(!puurgad)
        if (puurgad) setprgd(prgs - 1)
        if (!puurgad) setprgd(prgs + 1)
        call.onRunExternalRQST({
            url: "/posts/post/puurga",
            method: "POST",
            data: {
                id_post: id,
                puurga: puurgad ? 2 : 1
            }
        })
            .then(_ => {
                console.log('====================================');
                console.log("Puurga state ==>", _['status']);
                console.log('====================================');
            })
            .catch(err => {
                console.log('====================================');
                console.log("Error on like event ==>", err);
                console.log('====================================');
            })

    }

    const onComment = async () => {
        setisloading(true)
        toast("Non pris en charge pour le moment !")
    }

    const OneFile = ({ files }) => {
        const file = files[0]
        const tfile = getFileType({ fileName: file })

        switch (tfile) {
            case 1:
                return (
                    <ImageComponent source={file} key={Math.random()} />
                )
                break;
            case 2:
                return (
                    <Videocomponent source={file} key={Math.random()} />
                )
                break;
            default:
                return (<></>)
                break;
        }
    }

    React.useEffect(() => {
        console.log('====================================');
        console.log(files);
        console.log('====================================');
    }, [])

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3" key={askey}>
            <div className="card-body p-0 d-flex">
                <Link to={link}>
                    <figure className="avatar me-3">
                        <img src={`${baseURL}/${avatar}`} alt="avatar" className="shadow-sm rounded-circle w45" />
                    </figure>
                </Link>
                <Link to={link}>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                        {user}
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">il y a <strong className='text-primary'>{time}</strong></span>
                    </h4>
                </Link>
                <div className={`ms-auto pointer d-flex align-items-center fw-600 text-grey-900 font-xssss ${menuClass}`} id={`dropdownMenu${id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}>
                    <i className={`ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss`}></i>
                </div>
            </div>
            <div className="card-body p-0 me-lg-5">
                <p className="fw-500 text-grey-500 lh-26 font-xssss w-100 mb-2">
                    {readmore ? des : truncateString({ text: des })}
                    {" "}
                    {String(des).length > maxShowLengthStringPost && (
                        <a
                            href="#"
                            onClick={e => {
                                e.preventDefault()
                                setreadmore(!readmore)
                            }}
                            className="fw-600 text-primary ms-2">
                            {readmore ? "Afficher moins" : "Afficher plus"}
                        </a>
                    )}
                </p>
            </div>
            {
                files &&
                (
                    <>
                        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
                            <div className="row ps-2 pe-2">
                                {files.length === 1 && (<OneFile files={files} />)}
                                {/* ============================================= */}
                                {files.length > 1 && Array.from(files).map((file, key) => {

                                    const tfile = getFileType({ fileName: file })
                                    switch (tfile) {
                                        case 1:
                                            return (
                                                <div className="col-sm-6 p-1 pointer" key={key} onClick={(e) => openLightbox(e, { index: key, photo: file })}>
                                                    <img src={`${baseURL}/${file}`} className="rounded-3 w-100" alt="post" />
                                                </div>
                                            )
                                            break;
                                        case 2:
                                            return (
                                                <div className="col-sm-6 p-1 pointer" key={Math.random()}>
                                                    <Videocomponent ref={videoRef} source={file} key={key} />
                                                </div>
                                            )
                                            break;
                                        default:
                                            return (<></>)
                                            break;
                                    }
                                })}
                            </div>
                        </div>
                    </>
                )
            }
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={Array.from(files).filter(f => {
                                const tfile = getFileType({ fileName: f })
                                return tfile === 1
                            }).map(x => ({ source: `${baseURL}/${x}` }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <div className="card-body d-flex p-0">
                <a
                    href="#"
                    className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss bg-grey py-1 px-2 mx-1 me-3"
                    style={{ borderRadius: 40, justifyContent: "space-around" }}
                    onClick={e => {
                        e.preventDefault()
                        onPuurga()
                    }}
                >
                    <i className={`icon-puurga ${puurgad ? "text-white bg-primary-gradiant" : "text-dark"} me-1 btn-round-xs font-xss me-2`}></i>
                    <b className="d-none-xss">{parseInt(prgs) !== 0 ? prgs : ""}</b>
                </a>
                <a
                    href="#"
                    className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss bg-grey py-1 px-2 mx-1 me-3"
                    style={{ borderRadius: 40, justifyContent: "space-around" }}
                    onClick={e => {
                        e.preventDefault()
                        onLike()
                    }}
                >
                    <i className={`feather-thumbs-up ${liked ? "text-white bg-primary-gradiant" : "text-dark"} me-1 btn-round-xs font-xss me-2`}></i>
                    <b className="d-none-xss">{parseInt(lks) !== 0 ? lks : ""}</b>
                </a>
                <a
                    href="#"
                    className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss bg-grey px-2 py-1 mx-1 me-3"
                    style={{ borderRadius: 40, justifyContent: "space-around" }}
                    onClick={e => {
                        e.preventDefault()
                        onComment()
                    }}
                >
                    <i className="feather-message-circle text-white bg-primary-gradiant me-1 btn-round-xs font-xss me-2"></i>
                    <b className="d-none-xss">{parseInt(cmmts) !== 0 ? cmmts : ""}</b>
                </a>
            </div>
        </div >
    );
}

export default Postview;